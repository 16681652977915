import * as React from 'react';

import { Footer, GoogleNoIndex, NavMenu, PageTitle } from '../components/shared';

import { BusinessReviews } from '../components/home';
import { Container } from 'react-bootstrap';

const ThankSharingIssueQuestionPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <NavMenu solidColor notFixed />
      <section id="thank-you">
        <Container fluid="lg" className="content">
          <h1 className="mb-4">Thank you for submitting your issue or question.</h1>
          <div className="mb-4">A Texas Tax Protest team member will respond to you shortly.</div>
        </Container>
      </section>
      <BusinessReviews />
      <Footer />
    </>
  );
};

export default ThankSharingIssueQuestionPage;
